<ng-container *ngIf="componentObservables$ | async"></ng-container>
<ng-container *ngIf="loading$ | async as loading">
	<div class="global-loading" *ngIf="loading.show" @fade>
		<div class="loader">
			<div class="btr-logo-container">
				<img class="btr-logo" src="assets/images/BTR-Logo.svg" alt="BTR Logo" />
			</div>
			<div class="loader_cogs">
				<div class="loader_cogs__top">
					<div class="top_part"></div>
					<div class="top_part"></div>
					<div class="top_part"></div>
					<div class="top_hole"></div>
				</div>
				<div class="loader_cogs__left">
					<div class="left_part"></div>
					<div class="left_part"></div>
					<div class="left_part"></div>
					<div class="left_hole"></div>
				</div>
				<div class="loader_cogs__bottom">
					<div class="bottom_part"></div>
					<div class="bottom_part"></div>
					<div class="bottom_part"></div>
					<div class="bottom_hole"></div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<app-groupui-discard-changes-modal></app-groupui-discard-changes-modal>
<app-confirmation-modal></app-confirmation-modal>
<btr-notification-card [(fieldSettings)]="errorNotificationCard"></btr-notification-card>
<btr-notification-card [(fieldSettings)]="authenticationFailedNotificationCard" (primaryClick)="onLogout()"></btr-notification-card>
<btr-app-update-modal [(showUpdateAppModal)]="showUpdateAppModal"></btr-app-update-modal>
<router-outlet></router-outlet>
