import { CONFIRMATION_MODAL_ACTION_DEFAULT_ICON, CONFIRMATION_MODAL_ACTION_DEFAULT_SEVERITY, ConfirmationModal } from './confirmation-modal.model';
import { Component, inject } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ConfirmationModalService } from './confirmation-modal.service';
import { FormsModule } from '@angular/forms';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { Observable } from 'rxjs';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
	selector: 'app-confirmation-modal',
	standalone: true,
	imports: [GroupUiModule, TranslocoModule, RouterModule, CommonModule, FormsModule],
	templateUrl: './confirmation-modal.component.html',
	styleUrl: './confirmation-modal.component.scss',
})
export class ConfirmationModalComponent {
	readonly confirmationModal$: Observable<ConfirmationModal | null> = inject(ConfirmationModalService).showConfirmationModal$;
	readonly ICON = CONFIRMATION_MODAL_ACTION_DEFAULT_ICON;
	readonly SEVERITY = CONFIRMATION_MODAL_ACTION_DEFAULT_SEVERITY;
}
