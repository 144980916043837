/**
 * Safely convert utf8 strings to base64, ensuring that "Character Out Of Range" exception when calling `window.btoa()` is avoided
 * @link https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem:~:text=arbitrary%20Unicode%20text.-,The%20%22Unicode%20Problem%22,-Since%20btoa%20interprets
 */
export function safeEncodeUtf8StringToBase64(utf8String: string): string {
	const fileContentBytes = new window.TextEncoder().encode(utf8String);
	const binString = Array.from(fileContentBytes, (byte) => String.fromCodePoint(byte)).join('');
	return window.btoa(binString);
}

export function safeDecodeBase64ToBytes(base64String: string):string {
	const binString = window.atob(base64String);
	const unit8Array = Uint8Array.from(binString, (m) => m.codePointAt(0) as number);
	const decodedBytes = new TextDecoder().decode(unit8Array)
	return decodedBytes;
}
