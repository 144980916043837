<ng-container *transloco="let t">
	<groupui-modal
		*ngIf="discardChanges$ | async as discardChanges"
		s="12"
		m="8"
		l="6"
		xl="4"
		xxl="3"
		[displayed]="true"
		(groupuiModalClose)="discardChanges?.onCancel()">
		<div class="display-flex flex-direction-column gap-groupui-spacing-7">
			<groupui-headline heading="h3">{{ t($any(discardChanges).discardChangesHeader) }} </groupui-headline>
			<groupui-text>{{ t($any(discardChanges).discardChangesMessage) }}</groupui-text>
			<div class="display-flex align-items-center justify-content-flex-end gap-groupui-spacing-3">
				<groupui-button type="button" variant="secondary" icon="arrow-left-24" (click)="discardChanges?.onCancel()">{{
					t('SHARED.BUTTON.BACK')
				}}</groupui-button>
				<groupui-button type="submit" icon="close-24" (click)="discardChanges?.onConfirm()">{{ t('SHARED.BUTTON.DISCARD') }}</groupui-button>
			</div>
		</div>
	</groupui-modal>
</ng-container>
