import { CONFIRMATION_MODAL_TYPE, ConfirmationModal, ConfirmationModalOptions } from './confirmation-modal.model';
import { Injectable, inject } from '@angular/core';

import { Subject } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root',
})
export class ConfirmationModalService {
	private translocoService = inject(TranslocoService);
	/**
	 * @description Broadcasting if discard changes modal show be opened or closed
	 */
	private confirmationModal$ = new Subject<ConfirmationModal | null>();
	/**
	 * @description Public source to subscribe to modal
	 */
	showConfirmationModal$ = this.confirmationModal$.asObservable();

	public async showDeleteConfirmation(options?: ConfirmationModalOptions): Promise<boolean> {
		const deleteOptions: ConfirmationModalOptions = {
			title: this.translocoService.translate(options?.title || 'SHARED.MODAL.TITLE#DELETE_CONFIRMATION'),
			message: this.translocoService.translate(options?.title || 'SHARED.MODAL.MESSAGE#DELETE_CONFIRMATION'),
			...options,
			type: CONFIRMATION_MODAL_TYPE.DELETE,
		};
		const res = await this.showConfirmationModal(deleteOptions);
		return res;
	}

	/**
	 * @description Triggers the discard changes modal to show and wait for it's response
	 */
	public showConfirmationModal(options: ConfirmationModalOptions): Promise<boolean> {
		return new Promise((res) => {
			this.confirmationModal$.next({
				...options,
				onConfirm: () => {
					this.confirmationModal$.next(null);
					res(true);
				},
				onCancel: () => {
					this.confirmationModal$.next(null);
					res(false);
				},
			});
		});
	}
}
