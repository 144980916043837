import { Contract, ContractFullDetails, ContractHistory, ContractRequest, ContractState } from '@core/graphql/generated-types';
import { ContractCreateFormValue, ContractTableFilterValue, ContractTableFilterValueKeys } from './contract.interface';
import { ParamMap, Params } from '@angular/router';
import { filter, first, last, sortBy, trim } from 'lodash';
import { firstValueFrom, lastValueFrom } from 'rxjs';

import { ContractAdapterService } from './contract-adapter.service';
import { DateTime } from 'luxon';
import { FetchPolicy } from '@apollo/client';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ContractService {
	constructor(private contractAdapter: ContractAdapterService) {}

	serializeContractStateForQueryParams(selectedStates: ContractState[] | null): string | null {
		if (!selectedStates) {
			return null;
		}
		return selectedStates.join(environment.config.url.separator);
	}

	deSerializeContractStateFromQueryParams(value: string): ContractState[] {
		return value?.split(environment.config.url.separator) as ContractState[];
	}

	getContractsByPartnerId(contractStates?: ContractState[], fetchPolicy: FetchPolicy = 'network-only'): Promise<ContractFullDetails[]> {
		return firstValueFrom(this.contractAdapter.getContractsByPartnerId(contractStates, fetchPolicy));
	}
	contractByPermission(fetchPolicy: FetchPolicy = 'network-only'): Promise<Contract[]> {
		return firstValueFrom(this.contractAdapter.contractByPermission(fetchPolicy));
	}

	filterContractByStates(contracts: Contract[], states: ContractState[]): Contract[] {
		if (!states) {
			return contracts;
		}
		return sortBy(
			filter(contracts, (item) => states.includes(item.State as ContractState)),
			'ContractId',
		);
	}

	async contractHistoryById(contractId: string, fetchPolicy: FetchPolicy): Promise<ContractHistory[]> {
		return await firstValueFrom(this.contractAdapter.contractHistoryById(contractId, fetchPolicy));
	}

	async createContract(value: ContractCreateFormValue): Promise<string> {
		const contractRequest: ContractRequest = {
			Customer: value.customerPartnerId,
			Supplier: value.supplierPartnerId,
			PartNumber: value.partNumber,
			PartType: value.partType,
		};
		const contract = await lastValueFrom(this.contractAdapter.registerContract(contractRequest));
		return contract?.ContractId as string;
	}

	getContractByContractId(contractId: string, fetchPolicy: FetchPolicy): Promise<ContractFullDetails> {
		return firstValueFrom(this.contractAdapter.getContractFullDetailsById(contractId, fetchPolicy));
	}

	filterContracts(data: ContractFullDetails[], filterValue: ContractTableFilterValue): ContractFullDetails[] {
		const filterTextSearch = trim(filterValue.textSearch as string).toLowerCase();
		const filterLastModified = trim(filterValue.lastModified as string).toLowerCase();
		const filterStateSelection = filterValue.stateSelection;
		if (!filterTextSearch && !filterLastModified && !filterStateSelection?.length) {
			return data;
		}
		const startDate = first(filterLastModified.split(':')) as string;
		const endDate = last(filterLastModified.split(':')) as string;
		return data.filter((item) => {
			const itemTextSearch = (item.PartType as string).concat(item.PartNumber as string, item.ContractId as string).toLowerCase();
			const itemStateSelection = item.State;
			const hasTextSearchMatch = filterTextSearch ? itemTextSearch.includes(filterTextSearch) : true;
			const hasLastModifiedMatch = this.isInDateRange(item.LastModified as string, startDate, endDate);
			const hasStateSelectionMatch = filterStateSelection ? filterStateSelection.includes(itemStateSelection as ContractState) : true;
			return hasTextSearchMatch && hasLastModifiedMatch && hasStateSelectionMatch;
		});
	}

	isInDateRange(targetDate: string, start: string, end: string): boolean {
		if (!targetDate || !start || !end) {
			return true;
		}
		return (
			DateTime.fromISO(targetDate) <= DateTime.fromISO(end, { zone: 'utc' }).endOf('day') &&
			DateTime.fromISO(targetDate) >= DateTime.fromISO(start, { zone: 'utc' }).startOf('day')
		);
	}

	async updateContractXsd(contractId: string, file: string, fileName: string): Promise<void> {
		await firstValueFrom(this.contractAdapter.updateContractXSD(contractId, file, fileName));
	}

	getContractXsdUrl(contractId: string): Promise<string | null> {
		return firstValueFrom(this.contractAdapter.getContractXsdUrl(contractId));
	}

	async updateContractState(state: ContractState, contractId: string): Promise<void> {
		await lastValueFrom(this.contractAdapter.updateContractState(state, contractId));
	}

	async deleteContract(contractId: string): Promise<void> {
		await lastValueFrom(this.contractAdapter.deleteContract(contractId));
	}

	/**
	 * @description Performs sanitization by picking only relevant values from queryParams
	 */
	getContractFilterQueryParams(paramMap: ParamMap, defaultFilterValue: ContractTableFilterValue): ContractTableFilterValue {
		// Define a type safe getter for the paramMap to prevent typos
		const typedGetFn = (name: ContractTableFilterValueKeys) => paramMap.get(name);
		return {
			textSearch: typedGetFn('textSearch') ?? defaultFilterValue.textSearch,
			lastModified: typedGetFn('lastModified') ?? defaultFilterValue.lastModified,
			stateSelection: this.deSerializeContractStateFromQueryParams(typedGetFn('stateSelection') as string) ?? defaultFilterValue.stateSelection,
		};
	}

	hasFilterValueChanged(formFilterValueParams: Params, currentUrlQueryParams: Params): boolean {
		const filterValue: Params = formFilterValueParams;
		const urlValue: Params = currentUrlQueryParams;
		for (const key in filterValue) {
			if (typeof filterValue[key] === "boolean") {
				filterValue[key] = filterValue[key].toString();
			}
			if (Array.isArray(filterValue[key])) {
				filterValue[key] = filterValue[key].join(",");
			}
		}
		return (JSON.stringify(filterValue) !== JSON.stringify(urlValue));		
	}
}
