export enum CONFIRMATION_MODAL_TYPE {
	CONFIRM = 'CONFIRM',
	SAVE = 'SAVE',
	DELETE = 'DELETE',
}
export const CONFIRMATION_MODAL_ACTION_DEFAULT_ICON = {
	[CONFIRMATION_MODAL_TYPE.CONFIRM]: 'check-24',
	[CONFIRMATION_MODAL_TYPE.SAVE]: 'save-24',
	[CONFIRMATION_MODAL_TYPE.DELETE]: 'trash-24',
};
export const CONFIRMATION_MODAL_ACTION_DEFAULT_SEVERITY = {
	[CONFIRMATION_MODAL_TYPE.CONFIRM]: 'success',
	[CONFIRMATION_MODAL_TYPE.SAVE]: 'success',
	[CONFIRMATION_MODAL_TYPE.DELETE]: 'danger',
};

export interface ConfirmationModalOptions {
	title?: string;
	message?: string;
	params?: object;
	type: CONFIRMATION_MODAL_TYPE;
}

export interface ConfirmationModal extends ConfirmationModalOptions {
	/**
	 * @description Will close modal
	 */
	onConfirm?: () => void;
	/**
	 * @description Will close modal
	 */
	onCancel?: () => void;
}
