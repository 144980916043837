<ng-container *transloco="let t">
	<groupui-modal *ngIf="confirmationModal$ | async as modal" s="12" m="8" l="6" xl="4" xxl="3" [displayed]="true" (groupuiModalClose)="modal?.onCancel()">
		<div class="display-flex flex-direction-column gap-groupui-spacing-7">
			<groupui-headline heading="h3">{{ t($any(modal).title, modal.params) }} </groupui-headline>
			<groupui-text *ngIf="modal.message">{{ t(modal.message, modal.params) }}</groupui-text>
			<div class="display-flex align-items-center justify-content-flex-end gap-groupui-spacing-3">
				<groupui-button type="button" variant="secondary" icon="close-24" (click)="modal?.onCancel()">
					{{ t('SHARED.BUTTON.CANCEL') }}
				</groupui-button>
				<groupui-button
					type="submit"
					variant="primary"
					[icon]="ICON[modal.type]"
					[severity]="SEVERITY[modal.type]"
					(click)="modal?.onConfirm()"
					[severity]="">
					{{ t('SHARED.BUTTON.' + modal.type) }}
				</groupui-button>
			</div>
		</div>
	</groupui-modal>
</ng-container>
